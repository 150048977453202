import { render, staticRenderFns } from "./FloatActivity.vue?vue&type=template&id=47c0aa86&scoped=true"
import script from "./FloatActivity.vue?vue&type=script&lang=js"
export * from "./FloatActivity.vue?vue&type=script&lang=js"
import style0 from "./FloatActivity.vue?vue&type=style&index=0&id=47c0aa86&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47c0aa86",
  null
  
)

export default component.exports